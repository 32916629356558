/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333333;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #333333;
  // background: #0d153a url('/images/bg.jpg') no-repeat right center / cover;
}
.wrap {
  padding: 30px 0;
  overflow-x: clip;
}
.container-fluid {
  &.but-fixed {
    max-width: 1356px;
  }
  &.wider {
    max-width: 1800px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header-wrap {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
#header {
  // background: rgba(255, 255, 255, 0.8);
  .logos {
    max-width: 350px;
  }
  a.logo {
    border: 0;
    width: 100%;
    // max-width: 200px;
    // min-width: 200px;
    img {
      width: auto;
      height: auto;
      max-width: 175px;
      &.metrix {
        max-width: 80px;
      }
    }
  }
  #support {
    p {
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  a.social {
    height: 30px;
    width: 30px;
    margin: 3px;
    font-size: 16px;
  }
  a.social [class^='icon-']:before {
    line-height: 30px;
  }
}
#footer-wrap {
  background-color: #282828;
}
.footer {
  width: 100%;
  background-color: transparent;
  color: #fff;
  // border-top: 1px solid #e4e4e4;
  font-size: 1rem;
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: inherit;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  ul.links {
    padding-left: 0;
    list-style: none;
    li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
  a.social {
    height: 40px;
    width: 40px;
    margin: 0 5px;
    color: #fff !important;
    //background-color: rgba(255, 255, 255, 0.3);
    background-color: transparent;
    font-size: 19px;
  }
  a.social [class^='icon-']:before {
    line-height: 41px;
  }
  a.social:hover,
  a.social:active {
    font-size: 21px;
  }
}

#banner-carousel-wrap {
  position: relative;
  background-color: $theme-primary;
  .banner-carousel {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    // transition: height ease-in-out 0.03s, opacity ease-in-out 0.03s;
    &.ready {
      max-height: 300px;
      height: 300px;
      opacity: 1;
    }
    img {
      width: 100%;
    }
    .title {
      color: #000;
      background: rgba(255, 255, 255, 0.5);
      padding: 10px 15px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
    .slide1 {
      background: #fff url(/images/banners/online-training-webinars-sm.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    .slide2 {
      background: #fff url(/images/banners/instructor-led-classes-sm.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    .slide3 {
      background: #fff url(/images/banners/podcast-marketing-sm.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 70px;
    width: 100%;
    top: -1px;
    left: 0;
    z-index: 100;
    opacity: 0;
    transition: opacity ease-in-out 0.03s;
  }

  &.ready {
    &:before,
    &:after {
      opacity: 1;
    }
  }
  .tns-controls button {
    display: none;
  }
  .tns-nav {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    button {
      transition: 0.3s;
      &.tns-nav-active {
        transform: scale(1.6);
      }
    }
  }
}

#bottom-carousel-wrap {
  position: relative;
  background-color: $theme-primary;
  .bottom-carousel {
    overflow: hidden;
    max-height: 400px;
    height: 400px;
    img {
      width: 100%;
    }
    .slide1 {
      background: #fff url(/images/banners/zoom.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    .slide2 {
      background: #fff url(/images/banners/calendar.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    .slide3 {
      background: #fff url(/images/banners/success.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
  }
  .text-shadow {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 70px;
    width: 100%;
    top: -1px;
    left: 0;
    z-index: 100;
    opacity: 0;
    transition: opacity ease-in-out 0.03s;
  }
  .tns-controls button {
    display: none;
  }
  .tns-nav {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    button {
      transition: 0.3s;
      &.tns-nav-active {
        transform: scale(1.6);
      }
    }
  }
}

#banner-wrap {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  &.overlay {
    position: relative;
    background-color: darken($theme-primary, 10%);
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.5;
      background-color: #000;
    }
  }
  &.afop {
    background: #1c2c17 url('/images/banners/afop.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.case {
    background: #1c2c17 url('/images/banners/case.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.centralregion {
    background: $theme-primary url('/images/banners/centralregion.jpg') no-repeat 50% 30%;
    background-size: cover;
  }
  &.cert {
    background: $theme-primary url('/images/banners/certification-series.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.conferences {
    background: $theme-primary url('/images/banners/mike-speech.jpg') no-repeat 50% 25%;
    background-size: cover;
  }
  &.consulting {
    background: $theme-primary url('/images/banners/employer-services.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.cps {
    background: $theme-primary url('/images/banners/cps.jpg') no-repeat 50% 30%;
    background-size: cover;
  }
  &.goat {
    background: $theme-primary url('/images/banners/goat.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.interview {
    background: $theme-primary url('/images/banners/interview.jpg') no-repeat 50% 25%;
    background-size: cover;
  }
  &.lms {
    background: $theme-primary url('/images/banners/tas-pic.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.logo {
    background: $theme-primary url('/images/banners/workforce180-logo-fd.png') no-repeat 50% 50%;
    background-size: cover;
  }
  &.marketing {
    background: $theme-primary url('/images/banners/workforce-web.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.northfl {
    background: $theme-primary url('/images/banners/northcentral-ocala-fl.jpg') no-repeat 50% 80%;
    background-size: cover;
  }
  &.ohio {
    background-color: #212121;
  }
  &.ojt {
    background: $theme-primary url('/images/banners/ojt.jpg') repeat-x 50% 50%;
    background-size: contain;
  }
  &.penn {
    background: $theme-primary url('/images/banners/pennsylvania.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.podcast {
    background: $theme-primary url('/images/banners/podcast-rocket.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.podcast-zone {
    background: $theme-primary url('/images/banners/podzone.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.resource {
    background: $theme-primary url('/images/banners/resource-hub.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.rtb {
    background: $theme-primary url('/images/banners/rtb.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.time {
    background: $theme-primary url('/images/banners/time.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.women {
    background: $theme-primary url('/images/banners/business-for-women.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
}

#stats-wrap {
  .number {
    color: #4c78e3;
  }
}

#brands-wrap {
  .brand-card {
    min-height: 200px;
    position: relative;
    .brand-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f0f0f0;
      transition: opacity 0.15s ease-in;
      opacity: 0;
    }
    &:hover .brand-info {
      opacity: 1;
    }
    svg {
      width: 3.5em;
      height: 3.5em;
      margin-right: 15px;
    }
  }
}

#catalog-wrap {
  .card-img-top {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  img.avatar {
    background: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
}

#course-box {
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
  }
}
.cta-btn.grow {
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
}
.cta-btn.arrow {
  svg {
    margin-top: -4px;
  }
}
.input-group > .input-group-append > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.border-right-dot {
  border-right: 1px dotted #ccc;
}
.border-left-dot {
  border-left: 1px dotted #ccc;
}
.dropdown-list {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  span.dropdown.cta-dropdown {
    padding: 22px;
    padding-bottom: 20px;
    color: #fff;
    font-weight: 600;
    background: $cta-green;
    border: 0;
    width: 100%;
    min-width: 280px;
    max-width: 280px;
    height: auto;
    line-height: 1;
    font-size: 1.15rem;
    text-align: left;
    font-family: 'Montserrat', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    &.small {
      padding: 15px;
      padding-bottom: 13px;
      height: auto;
      line-height: 1;
      font-size: 1rem;
    }
    &.outline {
      background: transparent;
      color: $cta-green;
      border: 1px solid $cta-green;
    }
  }
  .drop {
    top: 60px;
    &.small {
      top: 44px;
      &.outline {
        top: 46px;
      }
    }
  }
}

.text-transform-none {
  text-transform: initial;
}

.video-img-hover {
  img {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}

.blue-highlight {
  background: #0e6aad;
  letter-spacing: 2px;
}

.text-outline {
  color: white;
  text-shadow: 2px 2px 0 $theme-primary, -1px -1px 0 $theme-primary, 1px -1px 0 $theme-primary, -1px 1px 0 $theme-primary,
    1px 1px 0 $theme-primary;
}

/* NEWSLETTER FORM */
.ctct-form-embed.form_1 .ctct-form-defaults {
  background-color: transparent !important;
}
.ctct-form-embed.form_1 .ctct-form-defaults .ctct-form-text,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-label,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-listname,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-lists-legend,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-date-slash,
.ctct-form-embed.form_1 .ctct-form-defaults .ctct-gdpr-text,
.ctct-form-embed.form_1 .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-link,
.ctct-form-embed.form_1 .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-privacy-link {
  color: #fff !important;
  font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
}
div.ctct-form-embed div.ctct-form-defaults h2.ctct-form-header,
div.ctct-form-embed form.ctct-form-custom .ctct-form-errorMessage {
  font-family: 'Montserrat', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
}
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-button {
  font-family: 'Montserrat', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
  font-weight: 600;
  max-width: 250px;
  margin: 0 auto;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo {
    max-width: 200px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    max-width: 250px;
  }
  #brands-wrap {
    .brand-card {
      min-height: 275px;
    }
  }
  .border-md-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #banner-carousel-wrap {
    .banner-carousel {
      &.ready {
        max-height: 550px;
        height: 550px;
      }
      .title {
        position: relative;
        top: calc(50% - 20px);
        font-size: 3em;
        line-height: 1.15em;
      }
      .slide1 {
        background: #fff url('/images/banners/online-training-webinars.jpg') no-repeat 50% 10%;
        background-size: cover;
        .title {
          top: 30%;
        }
      }
      .slide2 {
        background: #fff url('/images/banners/instructor-led-classes.jpg') no-repeat 50% 50%;
        background-size: cover;
        .title {
          top: 20%;
        }
      }
      .slide3 {
        background: #fff url('/images/banners/podcast-marketing.jpg') no-repeat 50% 50%;
        background-size: cover;
        .title {
          top: 30%;
        }
      }
    }
  }
  #brands-wrap {
    .brand-card {
      min-height: 320px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header a.logo {
    max-width: 280px;
  }
  #banner-carousel-wrap {
    .banner-carousel {
      .title {
        font-size: 3.7em;
      }
      .slide1 {
        .title {
          font-size: 2.5em;
        }
      }
    }
    .tns-controls button {
      display: block;
      position: absolute;
      top: calc(50% - 20px);
      background: transparent;
      border: 0;
      color: #555;
      transition: 0.3s;
      line-height: 40px;
      &:hover {
        background: transparent;
        color: #ccc;
      }
      &[data-controls='prev'] {
        left: 5px;
      }
      &[data-controls='next'] {
        right: 5px;
      }
    }
  }
  #brands-wrap {
    .brand-card {
      min-height: 260px;
    }
  }
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
}
