@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #2b6088 !important;
}

.text-theme-primary-alt {
  color: #2e4b7c !important;
}

.text-theme-secondary {
  color: #bf1c22 !important;
}

.text-theme-red {
  color: #be2327 !important;
}

.text-theme-danger {
  color: #fb4f16 !important;
}

.border-theme-secondary {
  border-color: #bf1c22 !important;
}

.text-yellow {
  color: #ff9900;
}

.text-green {
  color: #339966;
}

.text-orange {
  color: #f87e17 !important;
}

.text-teal {
  color: #48b8b8 !important;
}

.text-icon-blue {
  color: #2b6088;
}

.text-icon-green {
  color: #21d353;
}

.text-icon-pink {
  color: #ad1a74;
}

.text-icon-yellow {
  color: #cfc439;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #2b6088 !important;
}

.bg-theme-primary-alt {
  background-color: #2e4b7c !important;
}

.bg-theme-secondary {
  background-color: #bf1c22 !important;
}

.bg-theme-light {
  background-color: #f0f0f0 !important;
}

.bg-theme-callout {
  background-color: #bae7f5 !important;
}

.bg-theme-highlight {
  background-color: #34b9f6 !important;
}

.bg-theme-green {
  background-color: #468148 !important;
}

.bg-theme-orange {
  background-color: #f87e17 !important;
}

.bg-theme-teal {
  background-color: #48b8b8 !important;
}

.bg-gr-blue {
  background: #0b406c;
  background: linear-gradient(180deg, #0b406c 0%, #1280bf 100%);
}

.bg-gr-blue-med {
  background: #254d80;
  background: linear-gradient(320deg, #192d47 0%, #254d80 100%);
}

.bg-gr-blue-dark {
  background: #110022;
  background: linear-gradient(180deg, #065492 6%, #110022 89%);
}

.bg-gr-green {
  background: #3d6820;
  background: linear-gradient(180deg, #3d6820 0%, #70ad46 100%);
}

.bg-gr-grey {
  background: #555354;
  background: linear-gradient(180deg, #555354 0%, #a3a3a3 100%);
}

.bg-gr-orange {
  background: #bd4f04;
  background: linear-gradient(180deg, #bd4f04 0%, #ec7c30 100%);
}

.bg-gr-yellow {
  background: #bd8e02;
  background: linear-gradient(180deg, #bd8e02 0%, #febf00 100%);
}

/* Learning Hubs */
.bg-theme-central {
  background-color: #22053d !important;
}

.bg-theme-cps {
  background-color: #b777a3 !important;
}

/* ----------------------------------------------------------------------------
    Borders
----------------------------------------------------------------------------- */
.border-blue {
  border-color: #1280bf !important;
}

.border-green {
  border-color: #3d6820 !important;
}

.border-grey {
  border-color: #555354 !important;
}

.border-orange {
  border-color: #bd4f04 !important;
}

.border-secondary {
  border-color: #bf1c22 !important;
}

.border-yellow {
  border-color: #febf00 !important;
}

/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?24804043");
  src: url("../fonts/icons.eot?24804043#iefix") format("embedded-opentype"), url("../fonts/icons.woff?24804043") format("woff"), url("../fonts/icons.ttf?24804043") format("truetype"), url("../fonts/icons.svg?24804043#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}

a.social:hover,
a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.social.dribbble:hover,
a.social.dribbble:active {
  background-color: #ea4c89;
}

a.social.instagram:hover,
a.social.instagram:active {
  background-color: #49789d;
}

a.social.behance:hover,
a.social.behance:active {
  background-color: #1769ff;
}

a.social.facebook:hover,
a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover,
a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover,
a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover,
a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover,
a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover,
a.social.tumblr:active {
  background-color: #529ecc;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: Montserrat, var(--font-family);
  line-height: 1.4;
  color: #545454;
  margin-bottom: 0.75em;
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: 1.602em;
}

h2,
.h2 {
  font-size: 1.424em;
}

h3,
.h3 {
  font-size: 1.266em;
}

h4,
.h4 {
  font-size: 1.125em;
}

h5,
.h5 {
  font-size: 1em;
}

@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  h1,
  .h1 {
    font-size: 1.8em;
  }
  h2,
  .h2 {
    font-size: 1.55em;
  }
  h3,
  .h3 {
    font-size: 1.32em;
  }
  h4,
  .h4 {
    font-size: 1.125em;
  }
  h5,
  .h5 {
    font-size: 1em;
  }
}
@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em;
  }
  h2,
  .h2 {
    font-size: 1.728em;
  }
  h3,
  .h3 {
    font-size: 1.44em;
  }
  h4,
  .h4 {
    font-size: 1.2em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em;
  }
  h2,
  .h2 {
    font-size: 1.953em;
  }
  h3,
  .h3 {
    font-size: 1.563em;
  }
  h4,
  .h4 {
    font-size: 1.25em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

a {
  color: #284793;
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
}

a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #1d346b;
}

a.img,
a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
body.modal-open {
  overflow: auto !important;
  padding: 0 !important;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}

.dropdown-list ul.drop {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}

.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}

.dropdown-list span.dropdown:hover,
.dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: "\e817";
  color: ineherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

.dropdown-list span.dropdown.is-active:after {
  content: "\e816";
}

.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}

.dropdown-list .drop {
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}

.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}

.dropdown-list .drop li:first-child {
  border-top: 0;
}

.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.dropdown-list .drop li a:hover,
.dropdown-list .drop li a:active {
  background: #f1f1f1;
}

.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: inherit;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check > li:before {
  content: "\e840";
  font-family: "icons";
}

ul.check.circles > li {
  padding-left: 28px;
}

ul.check.circles > li:before {
  content: "\e840";
  font-family: icons;
  background: #ccc;
  padding: 0px 5px 0px;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  color: #fff;
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}
.accordion-wrap .accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
}
.accordion-wrap .accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion-wrap .accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: calc(1em + 10px);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.accordion-wrap .accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion-wrap .accordion .accordion-toggle:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.accordion-wrap .accordion.is-open > .accordion-content {
  padding-top: 5px;
  opacity: 1;
  transform: scaleY(1);
}
.accordion-wrap .accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.accordion-wrap .accordion .accordion-content {
  padding: 0 10px;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transition: max-height 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #e0e0e0 !important;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  margin: 10px;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  text-shadow: 0 0 40px #333;
  vertical-align: middle;
  color: transparent;
}

.price.static {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.price.shown {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollar-sign {
  filter: blur(10px);
}

.price.shown span.dollar-sign {
  filter: blur(0);
}

.price span.dollars:after,
.price span.dollars-fees:after,
.price span.cents:after,
.price span.cents-fees:after,
.price span.dollars:after,
.price span.cents:after {
  display: inline-block;
  content: "##";
  text-shadow: 0 0 20px currentcolor;
  filter: blur(10px);
}

.price.shown span.dollars:after,
.price.shown span.dollars-fees:after,
.price.shown span.cents:after,
.price.shown span.cents-fees:after,
.price.shown span.dollars:after,
.price.shown span.cents:after {
  display: none;
  filter: blur(0);
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
}
.lga-item.lga-replaced {
  display: inline;
  opacity: 1;
}

/* ----------------------------------------------------------------------------
  Accessibility styles
----------------------------------------------------------------------------- */
:is(nav) :is(ul) :focus-visible,
:is(.dropdown-list) :is(ul) :focus-visible {
  outline: 2px solid #284793 !important;
  outline-offset: -2px;
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: 100% !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: 100% !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: 100% !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: 100% !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.ls-10 {
  letter-spacing: 0.1rem;
}

.ls-15 {
  letter-spacing: 0.15rem;
}

.ls-20 {
  letter-spacing: 0.2rem;
}

.ls-25 {
  letter-spacing: 0.25rem;
}

.lh-1 {
  line-height: 1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-14 {
  line-height: 1.4;
}

.lh-16 {
  line-height: 1.6;
}

.lh-18 {
  line-height: 1.8;
}

.lh-20 {
  line-height: 2;
}

.lh-25 {
  line-height: 2.5;
}

@media (min-width: 992px) {
  .fs-lg-5 {
    font-size: 1.25rem !important;
  }
}
/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: transparent;
}

#menutoggle {
  display: block;
  margin: 0 5px 0 0;
  padding: 0 18px;
  width: auto;
  color: #fff;
  background: #2b6088;
  height: 50px;
  font-size: 1.1rem;
  font-family: "Montserrat", var(--font-family);
  font-weight: 600;
  line-height: 46px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
}
#menutoggle .hamburger-box {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
  vertical-align: middle;
}
#menutoggle .hamburger-box .hamburger-inner {
  display: block;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
#menutoggle .hamburger-box .hamburger-inner,
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, width;
  border-radius: 4px;
  background-color: #fff;
  right: 0;
}
#menutoggle .hamburger-box .hamburger-inner {
  top: 0;
  width: 25px;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  width: 22px;
}
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  display: block;
  content: "";
}
#menutoggle .hamburger-box .hamburger-inner::before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  top: 20px;
}
#menutoggle.is-active .hamburger-inner,
#menutoggle.is-active .hamburger-inner::after,
#menutoggle.is-active .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
#menutoggle.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
#menutoggle.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
#menutoggle:hover {
  background-color: #fae0e1;
}
#menutoggle:hover .hamburger-box .hamburger-inner,
#menutoggle:hover .hamburger-box .hamburger-inner::after,
#menutoggle:hover .hamburger-box .hamburger-inner::before {
  width: 30px;
}
#menutoggle:hover, #menutoggle:active, #menutoggle.is-active {
  background-color: #3475a5;
  border-radius: 4px 4px 0 0;
}
#menutoggle.is-active + ul {
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out;
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  font-family: "Montserrat", var(--font-family);
  overflow: visible;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
  border-top: 1px solid #64a1ce;
}
#nav > ul {
  visibility: hidden;
}
#nav ul {
  float: none;
  display: block;
  margin: -1px 0 0 0;
  padding: 0;
  width: 100%;
  opacity: 0;
  max-height: 0;
  background: #fff;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
  border-radius: 0 0 4px 4px;
  top: 1px;
  border: 0;
  position: relative;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background: #ecf3f9;
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
  box-shadow: 0 0 0;
  border-radius: 0;
}
#nav ul.submenu:hover {
  display: block !important;
}
#nav ul.submenu a {
  padding-left: 40px;
  background: none;
}
#nav ul.submenu a:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  border: 0;
  border-top: 1px dotted #3475a5;
}
#nav li:first-child {
  border-top: 0;
}
#nav li.onmobile {
  display: block;
}
#nav a,
#nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #2b6088;
  line-height: 1.6em;
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.17s linear 0s;
}
#nav span.submenu:after,
#nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active,
#nav a.submenu.is-active {
  border-bottom: 1px dotted #3475a5;
}
#nav span.submenu.is-active + ul.submenu,
#nav a.submenu.is-active + ul.submenu {
  max-height: 360px;
  overflow-y: auto;
}
#nav span.submenu.is-active:after,
#nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li:hover span.submenu,
#nav li:hover a.submenu,
#nav ul.submenu a:hover,
#nav ul.submenu a.is-active {
  color: #fff;
  background: #3475a5;
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  /* Large devices (desktops, 1200px and up) */
  #nav-wrap {
    background: #2b6088;
  }
  #menutoggle {
    display: none;
  }
  #nav {
    height: 45px;
    width: auto;
    background: transparent;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav > ul {
    visibility: visible;
  }
  #nav ul {
    float: left;
    width: 100%;
    height: auto;
    max-height: 45px;
    opacity: 1;
    margin: 4px 0;
    position: relative;
    z-index: 10;
    top: 0;
    overflow: visible;
    box-shadow: 0 0 0;
    border-radius: 0;
  }
  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    width: 320px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background: #ecf3f9;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:first-child a {
    border: 0;
  }
  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-top: 1px dotted #3e89c2;
  }
  #nav li {
    float: left;
    margin: 0 5px;
    padding: 0;
    height: 37px;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav a,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 14px;
    height: 37px;
    border: 0;
    color: #2b6088;
    text-shadow: none;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background 0.2s linear 0s;
    cursor: pointer;
  }
  #nav a.login {
    padding: 0 14px;
    border: 2px solid #3475a5;
    border-radius: 3px;
    line-height: 35px;
    text-transform: uppercase;
  }
  #nav a.login::after {
    display: none;
  }
  #nav a.login:hover {
    color: #fff;
    background-color: #3475a5;
  }
  #nav a.login:focus-visible {
    outline-color: #000 !important;
  }
  #nav span.submenu:after,
  #nav a.submenu:after {
    display: none;
  }
  #nav a:hover,
  #nav a.is-active,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav li:hover a.submenu,
  #nav ul.submenu a:hover,
  #nav span.submenu.is-active,
  #nav a.submenu.is-active {
    color: #fff;
    background: #3475a5;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav span.submenu.is-active.hover,
  #nav a.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    padding: 0 0;
    left: 0;
    max-height: 453px;
    opacity: 1;
    display: block !important;
    visibility: visible !important;
  }
  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.is-active {
    background: #2b6088;
  }
}
/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
@keyframes btnProcessing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  line-height: 1.35;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Montserrat", var(--font-family);
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  outline: 0;
  border-bottom: 0;
  overflow: hidden;
  transition: color 0.15s ease-in-out 0.03s, background-color 0.15s ease-in-out 0.03s, box-shadow 0.15s ease-in-out 0.03s;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.cta-btn.wide {
  max-width: 270px;
}
@media (min-width: 992px) {
  .cta-btn.wide.extra {
    max-width: 530px;
    padding: 10px 10px 8px 10px;
  }
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}
.cta-btn[data-pid] {
  position: relative;
  transition: padding-left 0.3s, opacity 0.5s;
}
.cta-btn[data-pid].btn-processing:before {
  display: inline-block;
  margin-right: 7px;
  content: "";
  top: 50%;
  left: 0.7rem;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border-radius: 20px;
  border: 3px solid #fff;
  border-top-color: black;
  border-left-color: black;
  opacity: 0.5;
  animation: btnProcessing 1s linear infinite;
}
.cta-btn.disabled {
  background: #ddd !important;
  pointer-events: none;
  color: #616161 !important;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #434343;
  border: 0;
}

.cta-btn.big {
  padding: 17px 5px 14px 5px;
  max-width: 280px;
  font-size: 1.25rem;
}

@media (min-width: 1200px) {
  .cta-btn.big {
    max-width: 300px;
  }
  .cta-btn.big.extra {
    max-width: 450px;
  }
}
.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.huge {
  padding: 25px 12px 23px 12px;
  font-size: 20px;
  max-width: 90%;
  line-height: 1.2em;
}

.cta-btn.x-small {
  padding: 7px 0 5px 0;
  font-size: 14px;
  max-width: 150px;
}

.cta-btn.small {
  padding: 9px 0 7px 0;
  font-size: 16px;
}

.cta-btn.small.fancy {
  line-height: 41px;
}

.cta-btn.small.outline {
  padding: 8px 0 6px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.orange {
  color: #fff;
  background: #ff6633;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #5cb865;
}

.cta-btn.red {
  color: #fff;
  background: #f44336;
}

.cta-btn.blue {
  color: #fff;
  background: #2b6088;
}

.cta-btn.gold {
  color: #fff;
  background: #ceae61;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.white.outline {
  color: #fff;
  background: rgba(255, 255, 255, 0.15);
  border-color: #fff;
}

.cta-btn.orange.outline {
  color: #ff6633;
  background: transparent;
  border-color: #ff6633;
}

.cta-btn.green.outline {
  color: #5cb865;
  background: transparent;
  border-color: #5cb865;
}

.cta-btn.red.outline {
  color: #f44336;
  background: transparent;
  border-color: #f44336;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #2b6088;
  background: transparent;
  border-color: #2b6088;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #434343;
}

.cta-btn.white.outline:active,
.cta-btn.white.outline:hover {
  color: #333;
  background: #fff;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff6633;
  background-image: linear-gradient(#ff855c, #ff531a);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #ff4b0f;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 102, 51, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #5cb865;
  background-image: linear-gradient(#78c480, #4cae56);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #49a752;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: rgba(92, 184, 101, 0.1);
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #f44336;
  background-image: linear-gradient(#f6675d, #f32c1e);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #f22314;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(244, 67, 54, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #2b6088;
  background-image: linear-gradient(#3576a7, #255275);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #3473a3;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: rgba(43, 96, 136, 0.1);
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

.cta-btn.gold:hover,
.cta-btn.gold:active {
  background: #c6a046;
}

.scroll-anim {
  opacity: 0;
  position: relative;
}

.fade-in {
  animation: fadeInAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide-in-top {
  animation: slideInTopAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInTopAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-bottom {
  animation: slideInBottomAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInBottomAnimation {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-left {
  animation: slideInLeftAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(-75%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-right {
  animation: slideInRightAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333333;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #333333;
}

.wrap {
  padding: 30px 0;
  overflow-x: clip;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}
.container-fluid.wider {
  max-width: 1800px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: #bf1c22 !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}

#header-wrap {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

#header .logos {
  max-width: 350px;
}
#header a.logo {
  border: 0;
  width: 100%;
}
#header a.logo img {
  width: auto;
  height: auto;
  max-width: 175px;
}
#header a.logo img.metrix {
  max-width: 80px;
}
#header #support p {
  margin: 2px 12px 0 0;
}
#header #support p strong {
  font-size: 16px;
}
#header #support a.login {
  padding: 0 12px 2px 12px;
  color: #fff;
  background: #2b6088;
  text-decoration: none;
  border-radius: 0 0 4px 4px;
  border: 0;
}
#header #support a.login:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e828";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support a.login:hover {
  background: #3475a5;
}
#header #support span.phone {
  padding-right: 8px;
  border-right: 1px dotted #333;
}
#header #support a.livechat {
  color: #555;
}
#header #support a.livechat:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e830";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header a.social {
  height: 30px;
  width: 30px;
  margin: 3px;
  font-size: 16px;
}
#header a.social [class^=icon-]:before {
  line-height: 30px;
}

#footer-wrap {
  background-color: #282828;
}

.footer {
  width: 100%;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
}
.footer p.trustmark {
  display: none;
  margin: 0;
}
.footer a {
  color: inherit;
}
.footer .social a {
  border: 0;
}
.footer img {
  display: inline-block;
}
.footer ul.links {
  padding-left: 0;
  list-style: none;
}
.footer ul.links li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.footer a.social {
  height: 40px;
  width: 40px;
  margin: 0 5px;
  color: #fff !important;
  background-color: transparent;
  font-size: 19px;
}
.footer a.social [class^=icon-]:before {
  line-height: 41px;
}
.footer a.social:hover,
.footer a.social:active {
  font-size: 21px;
}

#banner-carousel-wrap {
  position: relative;
  background-color: #2b6088;
}
#banner-carousel-wrap .banner-carousel {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
#banner-carousel-wrap .banner-carousel.ready {
  max-height: 300px;
  height: 300px;
  opacity: 1;
}
#banner-carousel-wrap .banner-carousel img {
  width: 100%;
}
#banner-carousel-wrap .banner-carousel .title {
  color: #000;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
#banner-carousel-wrap .banner-carousel .slide1 {
  background: #fff url(/images/banners/online-training-webinars-sm.jpg) no-repeat 50% 50%;
  background-size: cover;
}
#banner-carousel-wrap .banner-carousel .slide2 {
  background: #fff url(/images/banners/instructor-led-classes-sm.jpg) no-repeat 50% 50%;
  background-size: cover;
}
#banner-carousel-wrap .banner-carousel .slide3 {
  background: #fff url(/images/banners/podcast-marketing-sm.jpg) no-repeat 50% 50%;
  background-size: cover;
}
#banner-carousel-wrap:before, #banner-carousel-wrap:after {
  position: absolute;
  content: "";
  height: 70px;
  width: 100%;
  top: -1px;
  left: 0;
  z-index: 100;
  opacity: 0;
  transition: opacity ease-in-out 0.03s;
}
#banner-carousel-wrap.ready:before, #banner-carousel-wrap.ready:after {
  opacity: 1;
}
#banner-carousel-wrap .tns-controls button {
  display: none;
}
#banner-carousel-wrap .tns-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
#banner-carousel-wrap .tns-nav button {
  transition: 0.3s;
}
#banner-carousel-wrap .tns-nav button.tns-nav-active {
  transform: scale(1.6);
}

#bottom-carousel-wrap {
  position: relative;
  background-color: #2b6088;
}
#bottom-carousel-wrap .bottom-carousel {
  overflow: hidden;
  max-height: 400px;
  height: 400px;
}
#bottom-carousel-wrap .bottom-carousel img {
  width: 100%;
}
#bottom-carousel-wrap .bottom-carousel .slide1 {
  background: #fff url(/images/banners/zoom.jpg) no-repeat 50% 50%;
  background-size: cover;
}
#bottom-carousel-wrap .bottom-carousel .slide2 {
  background: #fff url(/images/banners/calendar.jpg) no-repeat 50% 50%;
  background-size: cover;
}
#bottom-carousel-wrap .bottom-carousel .slide3 {
  background: #fff url(/images/banners/success.jpg) no-repeat 50% 50%;
  background-size: cover;
}
#bottom-carousel-wrap .text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
#bottom-carousel-wrap:before, #bottom-carousel-wrap:after {
  position: absolute;
  content: "";
  height: 70px;
  width: 100%;
  top: -1px;
  left: 0;
  z-index: 100;
  opacity: 0;
  transition: opacity ease-in-out 0.03s;
}
#bottom-carousel-wrap .tns-controls button {
  display: none;
}
#bottom-carousel-wrap .tns-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
#bottom-carousel-wrap .tns-nav button {
  transition: 0.3s;
}
#bottom-carousel-wrap .tns-nav button.tns-nav-active {
  transform: scale(1.6);
}

#banner-wrap {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
#banner-wrap.overlay {
  position: relative;
  background-color: #1f4561;
}
#banner-wrap.overlay:before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.5;
  background-color: #000;
}
#banner-wrap.afop {
  background: #1c2c17 url("/images/banners/afop.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.case {
  background: #1c2c17 url("/images/banners/case.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.centralregion {
  background: #2b6088 url("/images/banners/centralregion.jpg") no-repeat 50% 30%;
  background-size: cover;
}
#banner-wrap.cert {
  background: #2b6088 url("/images/banners/certification-series.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.conferences {
  background: #2b6088 url("/images/banners/mike-speech.jpg") no-repeat 50% 25%;
  background-size: cover;
}
#banner-wrap.consulting {
  background: #2b6088 url("/images/banners/employer-services.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.cps {
  background: #2b6088 url("/images/banners/cps.jpg") no-repeat 50% 30%;
  background-size: cover;
}
#banner-wrap.goat {
  background: #2b6088 url("/images/banners/goat.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.interview {
  background: #2b6088 url("/images/banners/interview.jpg") no-repeat 50% 25%;
  background-size: cover;
}
#banner-wrap.lms {
  background: #2b6088 url("/images/banners/tas-pic.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.logo {
  background: #2b6088 url("/images/banners/workforce180-logo-fd.png") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.marketing {
  background: #2b6088 url("/images/banners/workforce-web.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.northfl {
  background: #2b6088 url("/images/banners/northcentral-ocala-fl.jpg") no-repeat 50% 80%;
  background-size: cover;
}
#banner-wrap.ohio {
  background-color: #212121;
}
#banner-wrap.ojt {
  background: #2b6088 url("/images/banners/ojt.jpg") repeat-x 50% 50%;
  background-size: contain;
}
#banner-wrap.penn {
  background: #2b6088 url("/images/banners/pennsylvania.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.podcast {
  background: #2b6088 url("/images/banners/podcast-rocket.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.podcast-zone {
  background: #2b6088 url("/images/banners/podzone.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.resource {
  background: #2b6088 url("/images/banners/resource-hub.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.rtb {
  background: #2b6088 url("/images/banners/rtb.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.time {
  background: #2b6088 url("/images/banners/time.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.women {
  background: #2b6088 url("/images/banners/business-for-women.jpg") no-repeat 50% 50%;
  background-size: cover;
}

#stats-wrap .number {
  color: #4c78e3;
}

#brands-wrap .brand-card {
  min-height: 200px;
  position: relative;
}
#brands-wrap .brand-card .brand-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  transition: opacity 0.15s ease-in;
  opacity: 0;
}
#brands-wrap .brand-card:hover .brand-info {
  opacity: 1;
}
#brands-wrap .brand-card svg {
  width: 3.5em;
  height: 3.5em;
  margin-right: 15px;
}

#catalog-wrap .card-img-top {
  width: 100%;
  height: auto;
  object-fit: cover;
}
#catalog-wrap img.avatar {
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
}

#course-box .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
}
.boxed-hover:hover {
  box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
}

.cta-btn.grow {
  transition: transform 0.3s ease;
}
.cta-btn.grow:hover {
  transform: scale(1.05);
}

.cta-btn.arrow svg {
  margin-top: -4px;
}

.input-group > .input-group-append > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.border-right-dot {
  border-right: 1px dotted #ccc;
}

.border-left-dot {
  border-left: 1px dotted #ccc;
}

.dropdown-list {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
.dropdown-list span.dropdown.cta-dropdown {
  padding: 22px;
  padding-bottom: 20px;
  color: #fff;
  font-weight: 600;
  background: #5cb865;
  border: 0;
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  height: auto;
  line-height: 1;
  font-size: 1.15rem;
  text-align: left;
  font-family: "Montserrat", "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.dropdown-list span.dropdown.cta-dropdown.small {
  padding: 15px;
  padding-bottom: 13px;
  height: auto;
  line-height: 1;
  font-size: 1rem;
}
.dropdown-list span.dropdown.cta-dropdown.outline {
  background: transparent;
  color: #5cb865;
  border: 1px solid #5cb865;
}
.dropdown-list .drop {
  top: 60px;
}
.dropdown-list .drop.small {
  top: 44px;
}
.dropdown-list .drop.small.outline {
  top: 46px;
}

.text-transform-none {
  text-transform: initial;
}

.video-img-hover img {
  opacity: 0.8;
}
.video-img-hover img:hover {
  opacity: 1;
}

.blue-highlight {
  background: #0e6aad;
  letter-spacing: 2px;
}

.text-outline {
  color: white;
  text-shadow: 2px 2px 0 #2b6088, -1px -1px 0 #2b6088, 1px -1px 0 #2b6088, -1px 1px 0 #2b6088, 1px 1px 0 #2b6088;
}

/* NEWSLETTER FORM */
.ctct-form-embed.form_1 .ctct-form-defaults {
  background-color: transparent !important;
}

.ctct-form-embed.form_1 .ctct-form-defaults .ctct-form-text,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-label,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-listname,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-lists-legend,
.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-date-slash,
.ctct-form-embed.form_1 .ctct-form-defaults .ctct-gdpr-text,
.ctct-form-embed.form_1 .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-link,
.ctct-form-embed.form_1 .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-privacy-link {
  color: #fff !important;
  font-family: "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
}

div.ctct-form-embed div.ctct-form-defaults h2.ctct-form-header,
div.ctct-form-embed form.ctct-form-custom .ctct-form-errorMessage {
  font-family: "Montserrat", "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
}

.ctct-form-embed.form_1 .ctct-form-custom .ctct-form-button {
  font-family: "Montserrat", "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
  font-weight: 600;
  max-width: 250px;
  margin: 0 auto;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  #header a.logo {
    max-width: 200px;
  }
}
@media (min-width: 768px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    max-width: 250px;
  }
  #brands-wrap .brand-card {
    min-height: 275px;
  }
  .border-md-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
}
@media (min-width: 992px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #banner-carousel-wrap .banner-carousel.ready {
    max-height: 550px;
    height: 550px;
  }
  #banner-carousel-wrap .banner-carousel .title {
    position: relative;
    top: calc(50% - 20px);
    font-size: 3em;
    line-height: 1.15em;
  }
  #banner-carousel-wrap .banner-carousel .slide1 {
    background: #fff url("/images/banners/online-training-webinars.jpg") no-repeat 50% 10%;
    background-size: cover;
  }
  #banner-carousel-wrap .banner-carousel .slide1 .title {
    top: 30%;
  }
  #banner-carousel-wrap .banner-carousel .slide2 {
    background: #fff url("/images/banners/instructor-led-classes.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-carousel-wrap .banner-carousel .slide2 .title {
    top: 20%;
  }
  #banner-carousel-wrap .banner-carousel .slide3 {
    background: #fff url("/images/banners/podcast-marketing.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-carousel-wrap .banner-carousel .slide3 .title {
    top: 30%;
  }
  #brands-wrap .brand-card {
    min-height: 320px;
  }
}
@media (min-width: 1200px) {
  #header a.logo {
    max-width: 280px;
  }
  #banner-carousel-wrap .banner-carousel .title {
    font-size: 3.7em;
  }
  #banner-carousel-wrap .banner-carousel .slide1 .title {
    font-size: 2.5em;
  }
  #banner-carousel-wrap .tns-controls button {
    display: block;
    position: absolute;
    top: calc(50% - 20px);
    background: transparent;
    border: 0;
    color: #555;
    transition: 0.3s;
    line-height: 40px;
  }
  #banner-carousel-wrap .tns-controls button:hover {
    background: transparent;
    color: #ccc;
  }
  #banner-carousel-wrap .tns-controls button[data-controls=prev] {
    left: 5px;
  }
  #banner-carousel-wrap .tns-controls button[data-controls=next] {
    right: 5px;
  }
  #brands-wrap .brand-card {
    min-height: 260px;
  }
}