/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #316ce8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #fb4f16 !default;
$orange: #f87e17 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #48b8b8 !default;
$cyan: #17a2b8 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #2b6088 !default;
$theme-primary-alt: #2e4b7c !default;
$theme-secondary: #bf1c22 !default;
$theme-danger: $danger !default;
$headline-primary: $theme-primary !default;
$headline-secondary: $theme-secondary !default;
$theme-nav-primary: $theme-secondary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color: #284793 !default;

/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
$cta-red: #f44336 !default;
$cta-orange: #ff6633 !default;
$cta-green: #5cb865 !default;
$cta-blue: $theme-primary !default;
$cta-yellow: #ffc107 !default;
$cta-dark: #555555 !default;
$cta-gold: #ceae61 !default;

// text color overrides
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-primary-alt {
  color: $theme-primary-alt !important;
}
.text-theme-secondary {
  color: $theme-secondary !important;
}
.text-theme-red {
  color: #be2327 !important;
}
.text-theme-danger {
  color: $theme-danger !important;
}
.border-theme-secondary {
  border-color: $theme-secondary !important;
}
.text-yellow {
  color: #ff9900;
}
.text-green {
  color: #339966;
}
.text-orange {
  color: $orange !important;
}
.text-teal {
  color: $teal !important;
}
.text-icon-blue {
  color: #2b6088;
}
.text-icon-green {
  color: #21d353;
}
.text-icon-pink {
  color: #ad1a74;
}
.text-icon-yellow {
  color: #cfc439;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: $theme-primary !important;
}
.bg-theme-primary-alt {
  background-color: $theme-primary-alt !important;
}
.bg-theme-secondary {
  background-color: $theme-secondary !important;
}
.bg-theme-light {
  background-color: #f0f0f0 !important;
}
.bg-theme-callout {
  background-color: #bae7f5 !important;
}
.bg-theme-highlight {
  background-color: #34b9f6 !important;
}
.bg-theme-green {
  background-color: #468148 !important;
}
.bg-theme-orange {
  background-color: $orange !important;
}
.bg-theme-teal {
  background-color: $teal !important;
}
.bg-gr-blue {
  background: #0b406c;
  background: linear-gradient(180deg, #0b406c 0%, #1280bf 100%);
}
.bg-gr-blue-med {
  background: #254d80;
  background: linear-gradient(320deg, #192d47 0%, #254d80 100%);
}
.bg-gr-blue-dark {
  background: #110022;
  background: linear-gradient(180deg, #065492 6%, #110022 89%);
}
.bg-gr-green {
  background: #3d6820;
  background: linear-gradient(180deg, #3d6820 0%, #70ad46 100%);
}
.bg-gr-grey {
  background: #555354;
  background: linear-gradient(180deg, #555354 0%, #a3a3a3 100%);
}
.bg-gr-orange {
  background: #bd4f04;
  background: linear-gradient(180deg, #bd4f04 0%, #ec7c30 100%);
}
.bg-gr-yellow {
  background: #bd8e02;
  background: linear-gradient(180deg, #bd8e02 0%, #febf00 100%);
}

/* Learning Hubs */
.bg-theme-central {
  background-color: #22053d !important;
}
.bg-theme-cps {
  background-color: #b777a3 !important;
}

/* ----------------------------------------------------------------------------
    Borders
----------------------------------------------------------------------------- */

.border-blue {
  border-color: #1280bf !important;
}
.border-green {
  border-color: #3d6820 !important;
}
.border-grey {
  border-color: #555354 !important;
}
.border-orange {
  border-color: #bd4f04 !important;
}
.border-secondary {
  border-color: $theme-secondary !important;
}
.border-yellow {
  border-color: #febf00 !important;
}
